<template>
  <b-row>
    <b-col md="6" class="my-1">
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="9">
              <small class="text-primary">Periode Renstra</small>
              <h3 class="text-primary"><strong>{{ data.item.bpj }}</strong></h3>
              <h6 class="text-warning"> <small><strong>Keterangan</strong> </small></h6>
              <small>{{ data.item.keterangan }}</small>
            </b-col>

            <b-col md="3">

              <b-button block variant="outline-warning" size="sm">
                <feather-icon icon="Edit3Icon" size="15"></feather-icon> Edit
              </b-button>
              <b-button block variant="outline-danger" size="sm">
                <feather-icon icon="TrashIcon" size="15"></feather-icon> Hapus
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [
        {
          id: 1,
          bpj: "2022-2024",
          kabupaten: [{
            nm_kab: 'Kapupaten Cilacap'
          },
          {
            nm_kab: 'Kapupaten Banyumas'
          },
          {
            nm_kab: 'Kapupaten Purbalingga'
          },
          ],
          keterangan: "Lorem Ipsum is simply dummy text Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"

        },
        {
          id: 1,
          bpj: "2024-2026",
          kabupaten: [{
            nm_kab: 'Kapupaten Kebumen'
          },
          {
            nm_kab: 'Kapupaten Purworejo'
          },
          {
            nm_kab: 'Kapupaten Magelang'
          },
          ],
          keterangan: "Lorem Ipsum is simply dummy text Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"

        }, {
          id: 1,
          bpj: "2025-2027",
          kabupaten: [{
            nm_kab: 'Kapupaten Kudus'
          },
          {
            nm_kab: 'Kapupaten Rembang'
          },
          {
            nm_kab: 'Kapupaten Jepara'
          },
          ],
          keterangan: "Lorem Ipsum is simply dummy text Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"

        },

      ],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>