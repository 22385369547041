<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="12">
                        <h5 class="text-primary">Tambah Periode Renstra</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Nama Resntra" label-for="Nama Renstra ">
                            <validation-provider #default="{ errors }" rules="required" name="Periode Tahun Anggaran">
                                <b-form-input v-model="input.th" :state="errors.length > 0 ? false : null"
                                    placeholder="Judul Periode Resntra" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group label="Tahun Pertama" label-for="Nama Renstra ">
                            <validation-provider #default="{ errors }" rules="required|integer"
                                name="Periode Tahun Anggaran">
                                <b-form-input v-model="input.th" :state="errors.length > 0 ? false : null"
                                    placeholder="Tahun Anggaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group label="Tahun Kedua" label-for="Nama Renstra ">
                            <validation-provider #default="{ errors }" rules="required|integer"
                                name="Periode Tahun Anggaran">
                                <b-form-input v-model="input.th" :state="errors.length > 0 ? false : null"
                                    placeholder="Tahun Anggaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group label="Tahun Ketiga" label-for="Nama Renstra ">
                            <validation-provider #default="{ errors }" rules="required|integer"
                                name="Periode Tahun Anggaran">
                                <b-form-input v-model="input.th" :state="errors.length > 0 ? false : null"
                                    placeholder="Tahun Anggaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Keterangan" label-for="defaultLabel">
                            <b-form-textarea v-model="input.keterangan" id="textarea-default" placeholder="Keterangan"
                                rows="3" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col md="6">
                        <!-- @click.prevent="validationForm" -->
                        <b-button variant="primary" size="sm">
                            <feather-icon icon="SaveIcon" size="15"></feather-icon> Simpan
                        </b-button>
                        <b-button variant="warning" size="sm" class="ml-1">
                            <feather-icon icon="RefreshCcwIcon" size="15"></feather-icon> Batal
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import {
    required, integer
} from '@validations'

export default {
    components: {
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BFormTextarea,
        BButton,
        FeatherIcon, ValidationProvider, ValidationObserver,
    },
    emits: ['simpanData'],
    data() {
        return {
            required, integer, input: {}
        }
    }, methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.simpanData(this.input);
                    this.input = {}
                }
            })
        },
        simpanData(value) {
            this.$emit("simpanData", value);
        }
    },
    computed: {

    },
}
</script>