<template>
  <div>
    <b-row>
      <b-col md="7">
        <b-card>
          <tabel_data></tabel_data>
        </b-card>
      </b-col>
      <b-col md="5">
        <b-card>
          <tambah_data></tambah_data>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import tabel_data from './tabel.vue';
import tambah_data from './form.vue';
export default {
  components: {
    BCard,
    BRow,
    BCol,
    tabel_data, tambah_data
  }
}
</script>

<style></style>