var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v("Tambah Periode Renstra")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Resntra","label-for":"Nama Renstra "}},[_c('validation-provider',{attrs:{"rules":"required","name":"Periode Tahun Anggaran"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Judul Periode Resntra"},model:{value:(_vm.input.th),callback:function ($$v) {_vm.$set(_vm.input, "th", $$v)},expression:"input.th"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Tahun Pertama","label-for":"Nama Renstra "}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"Periode Tahun Anggaran"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Tahun Anggaran"},model:{value:(_vm.input.th),callback:function ($$v) {_vm.$set(_vm.input, "th", $$v)},expression:"input.th"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Tahun Kedua","label-for":"Nama Renstra "}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"Periode Tahun Anggaran"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Tahun Anggaran"},model:{value:(_vm.input.th),callback:function ($$v) {_vm.$set(_vm.input, "th", $$v)},expression:"input.th"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Tahun Ketiga","label-for":"Nama Renstra "}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"Periode Tahun Anggaran"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Tahun Anggaran"},model:{value:(_vm.input.th),callback:function ($$v) {_vm.$set(_vm.input, "th", $$v)},expression:"input.th"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"defaultLabel"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Keterangan","rows":"3"},model:{value:(_vm.input.keterangan),callback:function ($$v) {_vm.$set(_vm.input, "keterangan", $$v)},expression:"input.keterangan"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{attrs:{"variant":"primary","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" Simpan ")],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"warning","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"RefreshCcwIcon","size":"15"}}),_vm._v(" Batal ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }